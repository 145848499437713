var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":"min(90%, 726px)","footer":null,"closable":true,"mask":true,"mask-closable":false,"visible":_vm.visible},on:{"cancel":_vm.closeModal}},[_c('div',{staticClass:"plausibility-report-loading-modal"},[_c('h3',{staticClass:"flex align-center txt-32 txt-bold"},[_vm._v(" "+_vm._s(_vm.$t('components.titles.addLanguage'))+" ")]),_c('div',{staticClass:"language-container"},[_c('h6',{style:({'margin-bottom': '24px'})},[_vm._v(_vm._s(_vm.$t('components.titles.addNewLanguages').toUpperCase()))]),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hide-required-mark":true}},_vm._l((_vm.newLanguages),function(_,index){return _c('a-form-item',{key:index},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("language" + index),
              {
                rules: [
                  {
                    validator: function () { return !_vm.activeLanguages.map(function (i) { return i.language; }).includes(_vm.newLanguages[index]); },
                    message: _vm.$t('components.notifications.languageAlreadyExists')
                  } ],
              } ]),expression:"[\n              `language${index}`,\n              {\n                rules: [\n                  {\n                    validator: () => !activeLanguages.map(i => i.language).includes(newLanguages[index]),\n                    message: $t('components.notifications.languageAlreadyExists')\n                  },\n                ],\n              },\n            ]"}],key:index,staticClass:"language-input",attrs:{"value":_vm.newLanguages[index],"placeholder":_vm.$t('components.titles.addANewLanguage')},model:{value:(_vm.newLanguages[index]),callback:function ($$v) {_vm.$set(_vm.newLanguages, index, $$v)},expression:"newLanguages[index]"}})],1)}),1),_c('div',{staticClass:"new-line-row",staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.addNewLanguage()}}},[_c('p',{staticClass:"add-icon"},[_vm._v("+")]),_c('span',{staticClass:"add-new-line-text"},[_vm._v(_vm._s(_vm.$t('components.titles.addNewLine')))])])],1),_c('div',{staticClass:"plausibility-report-download-button-container"},[_c('a-button',{staticClass:"mb-10 w170",attrs:{"disabled":_vm.disabledAddLanguages,"type":"primary","size":"large"},on:{"click":function($event){return _vm.addLanguages()}}},[_vm._v(" "+_vm._s(_vm.$t('components.titles.addLanguages'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }