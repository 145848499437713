<template>
  <a-modal
    width="min(90%, 726px)"
    :footer="null"
    :closable="true"
    :mask="true"
    :mask-closable="false"
    :visible="visible"
    @cancel="closeModal">
    <div
      class="plausibility-report-loading-modal">
      <h3 class="flex align-center txt-32 txt-bold">
        {{ $t('components.titles.addLanguage') }}
      </h3>
      <div class="language-container">
        <h6 :style="{'margin-bottom': '24px'}">{{ $t('components.titles.addNewLanguages').toUpperCase() }}</h6>
        <a-form layout="vertical" :form="form" :hide-required-mark="true">
          <a-form-item v-for="(_, index) in newLanguages" :key="index">
            <a-input
              :key="index"
              v-model="newLanguages[index]"
              v-decorator="[
                `language${index}`,
                {
                  rules: [
                    {
                      validator: () => !activeLanguages.map(i => i.language).includes(newLanguages[index]),
                      message: $t('components.notifications.languageAlreadyExists')
                    },
                  ],
                },
              ]"
              :value="newLanguages[index]"
              class="language-input"
              :placeholder="$t('components.titles.addANewLanguage')"/>
          </a-form-item>
        </a-form>
        <div class="new-line-row" style="display: flex" @click="addNewLanguage()">
          <p class="add-icon">+</p>
          <span class="add-new-line-text">{{ $t('components.titles.addNewLine') }}</span>
        </div>
      </div>
      <div class="plausibility-report-download-button-container">
        <a-button
          :disabled="disabledAddLanguages"
          class="mb-10 w170"
          type="primary"
          size="large"
          @click="addLanguages()">
          {{ $t('components.titles.addLanguages') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PlausibilityReportLoadingModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    close: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      newLanguages: [''],
      form: this.$form.createForm(this, { name: 'languages' }),
      disabledAddLanguages: true
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      projectId: (state) => state.survey.project.id,
      odkBuildForm: state => state.indicators.odkBuildForm || {},
      odkError: state => state.indicators.error,
      activeLanguages: state => state.indicators.activeLanguages
    })
  },
  watch: {
    newLanguages(oldVal, newVal) {
      this.form.validateFields((err, _) => {
        this.disabledAddLanguages = !!err || newVal.some(i => i === '');
      });
    }
  },
  methods: {
    ...mapActions([
      'loadSelectedIndicatorBuild',
      'createOdkBuildFormByJson',
      'saveActiveLanguages',
      'setFormBuilderAsChanged'
    ]),
    addNewLanguage() {
      this.newLanguages.push('');
    },
    addLanguages() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const addLanguages = this.newLanguages.filter(i => i !== '').map(item => ({ language: item }));
          this.saveActiveLanguages({ surveyId: this.surveyId, languages: addLanguages, partial: true }).then(_ => {
            if (this.odkError == null) {
              this.setFormBuilderAsChanged();
              this.closeModal();
            } else {
              this.$alert().danger(this.odkError.message);
            }
          });
        }
      });
    },
    closeModal() {
      this.newLanguages = [''];
      this.form.resetFields();
      this.close();
    }
  }
};
</script>

<style scoped>
.plausibility-report-loading-modal {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.plausibility-modal-body-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 26px;
  letter-spacing: -0.4px;
  color: #363534;
  margin-bottom: 76px;
  margin-top: 5px;
}
.plausibility-report-download-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: horizontal;
}
.add-icon {
  background:2rem;
  height: 25px;
  width: 25px;
  background-color: #e98300;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.625rem 0 0;
  color: white !important;
  font-weight: bold !important;
  font-size: 1.5em;
}
.language-container {
  border: 1px solid #d9d9d9;
  padding:30px;
  margin: 20px 0 100px;
}
.add-new-line-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
}
.language-input {
  width: 100%;
  margin: 5px 0 5px;
}
.new-line-row {
  margin: 24px 0;
  width: fit-content;
  display: flex;
  cursor: pointer;
}
</style>
